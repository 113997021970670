import { App } from './app';
import { CustomerLog } from './customer_log';
import { Media } from './media';
import { ProfilePlace } from './profile_place';
import { Section } from './section';
import { Template } from './template';
import { User } from './user';
import { Rss } from './rss';

export class Customer {

    public id: number|null = null;
    public name: string|null = null;
    public logo: string|null = null;
    public profile_images: Media[] = [];
    public name_and_city: string|null = null;
    public profile_places: ProfilePlace[] = [];
    public christmas_images: Media[] = [];
    public show: string|null = null;
    public status: number|null = null;
    public apps: App[] = [];
    public users: User[] = [];
    public sellers: User[] = [];
    public supporters: User[] = [];
    public app: App|null = null;
    public app_id: number|null = null;
    public city: string|null = null;
    public alternative_name: string|null = null;
    public sections: Section[] = [];
    public template_full: Template|null = null;
    public template_feed: Template|null = null;
    public template_web: Template|null = null;
    public template_web2: Template|null = null;
    public templates: Template[] = [];
    public feedTemplates: Template[] = [];
    public hasLunch: string|null = null;
    public hasQuiz: string|null = null;
    public hasNewsAd: string|null = null;
    public hasTakeOverAd: string|null = null;
    public hasWorkAd: string|null = null;
    public hasBooliAd: string|null = null;
    public hasMenu1Ad: string|null = null;
    public hasMenu2Ad: string|null = null;
    public hasIBecon: string|null = null;
    public hasProducts: string|null = null;
    public hasNotifyAll: string|null = null;
    public hasStudenOffers: string|null = null;
    public hasChristmas: string|null = null;
    public hasEvents: string|null = null;
    public hasPositionAd: string|null = null;
    public hasTemplates: string|null = null;
    public parent: Customer|null = null;
    public contracts_end: string|null = null;
    public contracts_value: number|null = null;
    public contracts_id: number|null = null;
    public support_mail:string|null = null;
    public lunch_order: number|null = null;
    public lunch_header: string|null = null;
    public showOneDayLunch: number|null = null;

    public logs: CustomerLog[] = [];

    public rsses: any[] = [];

    public lunch_url: string|null = null;

    public setting_profiletime: number|null = null;
    public number_ads: number|null = null;
    public score: number|null = null;

    public email: string|null = null;
    public showroom_mail: string|null = null;

    public phone: string|null = null;

    public following: boolean|null = null;
    public distance: number|null = null;

    public facebook_id: string|null = null;
    public instagram_id: string|null = null;
    

}

