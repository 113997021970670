
import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from '../../../../../shared/models/user';
import { ApiService } from 'src/app/Services/api.service';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/Services/data.service';
import { Customer } from '../../../../../shared/models/customer';
import { environment } from '../../../environments/environment';

class DataTablesResponse {
    data: any[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
  }
  
  @Component({
    selector: 'app-admin-customers',
    templateUrl: './admin-customers.component.html',
    styleUrls: ['./admin-customers.component.sass']
  })
  export class AdminCustomersComponent implements OnInit, OnDestroy {
    baseUrl:string = environment.apiUrl;
    heading = 'Alla kunder';
    subheading = 'Lista över alla företag och föreningar som är kunder. Välj Redigera för att ändra saker som hur namnet visas, vilka appar eller sektioner som kunden ska tillhöra och för att ladda upp logga.';
    icon = 'pe-7s-id icon-gradient bg-malibu-beach';
  
    public customers: Customer[] = [];
  
  
    dtOptions = {};
  
    constructor(private data: DataService, private http: HttpClient) {
    }
  
    ngOnInit(): void {
      const that = this;
  
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 25,
        serverSide: true,
        processing: true,
        order: [[0, 'desc']],
        language: {
          "emptyTable":     "Tabellen innehåller ingen data",
          "info":           "Visar rad _START_ - _END_ av _TOTAL_ rader",
          "infoEmpty":      "Visar 0 av 0 rader",
          "infoFiltered":   "(av totalt _MAX_ rader)",
          "infoPostFix":    "",
          "thousands":      ",",
          "lengthMenu":     "Visa _MENU_ rader per sida",
          "loadingRecords": "Laddar...",
          "processing":     "Laddar...",
          "search":         "Sök:",
          "zeroRecords":    "Inget innehåll hittat",
          "paginate": {
            "first":      "Första",
            "last":       "Sista",
            "next":       "Nästa",
            "previous":   "Föregående"
          },
          "aria": {
              "sortAscending":  ": activate to sort column ascending",
              "sortDescending": ": activate to sort column descending"
          }
      },
        ajax: (dataTablesParameters: any, callback) => {
          that.http
            .post<DataTablesResponse>(
              this.baseUrl + '/api/customers?uid=' + this.data.currentUser.id,
              dataTablesParameters, {}
            ).subscribe(resp => {
  
              that.customers = resp.data;
  
              callback({
                recordsTotal: resp.recordsTotal,
                recordsFiltered: resp.recordsFiltered,
                data: []
              });
            });
        },
        columns: [{ data: 'id' }, { data: 'name' }, { data: 'status' }]
      };
  
    }
  
    ngOnDestroy(): void {
    }
  
  
  
  }
  
