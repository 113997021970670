import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DataTablesResponse } from '../../../../../shared/models/datatabelsResponse';
import { GiftCard } from '../../../../../shared/models/giftcard';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-admin-gift-cards',
  templateUrl: './admin-gift-cards.component.html',
  styleUrls: ['./admin-gift-cards.component.scss']
})
export class AdminGiftCardsComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Alla presentkort';
  subheading = 'Här redigerar du alla presentkort';
  icon = 'pe-7s-phone icon-gradient bg-malibu-beach';

  public giftcards: GiftCard[] = [];


  dtOptions = {};

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
    const that = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      language: {
        "emptyTable":     "Tabellen innehåller ingen data",
        "info":           "Visar rad _START_ - _END_ av _TOTAL_ rader",
        "infoEmpty":      "Visar 0 av 0 rader",
        "infoFiltered":   "(av totalt _MAX_ rader)",
        "infoPostFix":    "",
        "thousands":      ",",
        "lengthMenu":     "Visa _MENU_ rader per sida",
        "loadingRecords": "Laddar...",
        "processing":     "Laddar...",
        "search":         "Sök:",
        "zeroRecords":    "Inget innehåll hittat",
        "paginate": {
            "first":      "Första",
            "last":       "Sista",
            "next":       "Nästa",
            "previous":   "Föregående"
        },
        "aria": {
            "sortAscending":  ": activate to sort column ascending",
            "sortDescending": ": activate to sort column descending"
        }
    },
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post<DataTablesResponse>(
            this.baseUrl + '/api/gift-vouchers',
            dataTablesParameters, {}
          ).subscribe(resp => {

            that.giftcards = resp.data;

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [{ data: 'id' }, { data: 'code' }, { data: 'amount_left' }, { data: 'expire' }]
    };

  }

  ngOnDestroy(): void {
  }



}
