import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { DataService } from 'src/app/Services/data.service';
import { ExternalAd } from '../../../../../shared/models/externalAd';
import { Response } from '../../../../../shared/models/response';

@Component({
  selector: 'app-external-ad-edit',
  templateUrl: './external-ad-edit.component.html',
  styleUrls: ['./external-ad-edit.component.scss']
})
export class ExternalAdEditComponent implements OnInit {

  heading = 'Skapa Extern Annons';
  subheading = 'Här skapar du externa annonser.';
  icon = 'pe-7s-helm icon-gradient bg-malibu-beach';

  public ad: ExternalAd = new ExternalAd();

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router) {

    
    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0)
    {

      this.heading = 'Redigera';
      this.subheading = 'Här redigerar du en annonsen';

      this.api.getExternalAd(id).subscribe((data: ExternalAd) => {
        this.ad = data;
      }, error => this.api.showError(error))
    }
    else
    {
      this.ad.id = 0;
      this.ad.type = "web";

    }

  }

  ngOnInit() {

  }



  save()
  {
    this.api.setExternalAd(this.ad).subscribe((data:Response)=>{
        this.ad.id = data.id;
        this.api.showSuccess("Sparad", "Annonsen sparades.");
    }, error => this.api.showError(error));
  }

  public delete()
  {
    if(confirm("Är du säker på att du vill ta bort den här annonsen?")) {

      this.api.delExternalAd(this.ad.id).subscribe((data:any) => {
        this.router.navigateByUrl('/external-ads');
      });
    }
  }


}
