import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-houseing-edit',
  templateUrl: './houseing-edit.component.html',
  styleUrls: ['./houseing-edit.component.scss']
})
export class HouseingEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
