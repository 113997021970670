import { Component, OnInit } from '@angular/core';
import { Ad } from '../../../../../shared/models/ad';
import { DataService } from 'src/app/Services/data.service';
import { HttpClient } from '@angular/common/http';
import { App } from '../../../../../shared/models/app';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { DataTablesResponse } from '../../../../../shared/models/datatabelsResponse';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-cronicles',
  templateUrl: './cronicles.component.html',
  styleUrls: ['./cronicles.component.scss']
})
export class CroniclesComponent implements OnInit {

  baseUrl:string = environment.apiUrl;
  heading = 'Alla krönikor';
  subheading = '';
  icon = 'pe-7s-photo icon-gradient bg-malibu-beach';

  public ads: Ad[] = [];
  public apps: App[];
  public selectedApps: App[] = [];

  dtOptions = {};

  constructor(private data: DataService, private http: HttpClient,
    private route: ActivatedRoute, private router: Router) {
      this.apps = this.data.currentUser.apps;
      this.selectedApps = this.data.currentUser.selectedApps;
  }

  ngOnInit(): void {
    const that = this;
    let columnist_id = 0;

    if (this.data.currentUser.role == 'Användare')
    {
      columnist_id = this.data.currentUser.id;
    }

    let appstr = "";

    if (this.selectedApps) {
      this.selectedApps.forEach(x => {
        appstr += x.id + ","
      })

      appstr += "-1"
    }

    this.dtOptions = {
      order: [[ 1, "desc" ]],
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      language: {
        "emptyTable":     "Det finns ingen data i tabellen",
        "info":           "Visar rad _START_ till _END_ av _TOTAL_ rader",
        "infoEmpty":      "Visar post 0 till 0 av 0 poster",
        "infoFiltered":   "(av totalt _MAX_ poster)",
        "infoPostFix":    "",
        "thousands":      ",",
        "lengthMenu":     "Visa _MENU_ rader per sida",
        "loadingRecords": "Laddar...",
        "processing":     "Laddar...",
        "search":         "Sök:",
        "zeroRecords":    "Inget matchar sökningen",
        "paginate": {
            "first":      "Första",
            "last":       "Sista",
            "next":       "Nästa",
            "previous":   "Föregående"
        },
        "aria": {
            "sortAscending":  ": activate to sort column ascending",
            "sortDescending": ": activate to sort column descending"
        }
    },
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post<DataTablesResponse>(
            this.baseUrl + '/api/ads?customer_id=' + this.data.currentUser.customer.id + "&appstr=" + appstr + "&columnist_id=" + columnist_id,
            dataTablesParameters, {}
          ).subscribe(resp => {

            that.ads = resp.data;

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [{ data: 'title' }, { data: 'timestart' }]
    };
  }

  ngOnDestroy(): void {
  }

  change() {
    this.data.currentUser.selectedApps = this.selectedApps;
    this.router.navigateByUrl('/cronicle/0', { skipLocationChange: true }).then(
      x => {
        this.router.navigateByUrl('/cronicles', { skipLocationChange: true });
      }
    )
  }
}
