
export class EventTime {

    public id: number;
    public event_id: number;
    public starttime: string;
    public stoptime: string;
    public showendtime: boolean;
    public wholeday: boolean;
    
}

