import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Ad } from '../../../../../shared/models/ad';
import { App } from '../../../../../shared/models/app';
import { Media } from '../../../../../shared/models/media';
import { DropzoneConfigInterface, DropzoneComponent } from 'ngx-dropzone-wrapper';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Response } from '../../../../../shared/models/response';
import { Serie } from '../../../../../shared/models/serie';
import { Byline } from '../../../../../shared/models/byline';
import { Subscription } from "rxjs";
import { AdTag } from '../../../../../shared/models/adtag';
import { environment } from '../../../environments/environment';
import { Template } from '../../../../../shared/models/template';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';

@Component({
  selector: 'app-news-edit',
  templateUrl: './news-edit.component.html',
  styleUrls: ['./news-edit.component.scss']
})
export class NewsEditComponent implements OnInit {
  baseUrl:string = environment.apiUrl;
  heading = 'Skapa nyhet';
  subheading = '';
  icon = 'pe-7s-note icon-gradient bg-arielle-smile';

  public ad: Ad = new Ad();
  public apps: App[] = [];
  public s: String;
  public mediaSearchResult: Media[] = [];
  public templates: Template[] = [];
  public preview_str: string;
  public preview_bodystr: string;
  public series: Serie[];
  public bylines: Byline[];
  public m_description: String[];
  private img_subscription: Subscription;

  public aiButtonImageSearch: boolean = true;
  public aiLoadingSocialMediaText: boolean = false;
  public postingFacebook: boolean = false;
  public postingInstagram: boolean = false;
  public warnings: string[] = [];
  
  public scrutinizer_body: any;
  public scrutinizer_header: any;
  public scrutinizer_puff: any;

  public img_search_method: number = 0;
  public loading_imgs: boolean = false;

  public dropConfig: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 100,
    autoReset: 10000,
    errorReset: null,
    cancelReset: null,
    timeout: 120000,
    url: this.baseUrl + '/api/media/upload?customer_id=' + this.data.currentUser.customer.id
  };
  @ViewChild(DropzoneComponent) DropZone?: DropzoneComponent;
  @ViewChild('preview_card') public preview_card: ElementRef;
  @ViewChild('news_preview') public news_preview: ElementRef;
  @ViewChild('news_preview2') public news_preview2: ElementRef;

  constructor(public data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router, private toastr: ToastrService, public http: HttpClient) {

    this.api.getCustomer(-1).subscribe(data => {
      this.templates = data.templates;
    })

/*
    setInterval(() => {

     this.warnings = [];
      if (this.ad?.title?.includes("."))
      {
        this.warnings.push("Du har en punkt i rubriken.")
      }
      if (this.ad?.body?.length > 100)
      {
        let end = this.ad.body.slice(-70)

        let br = end.split("<br>").length;
        let br2 = end.split("<br/>").length;
        let br3 = end.split("<br />").length;

        if (br + br2 + br3 > 5 )
        {
          this.warnings.push("Du verkar ha många radbrytningar i slutet av texten.")
        }
      }


    }, 5000)
*/

  }

  ngOnInit() {
    this.loadNews();


  }

  move(customer_id: number) {
    this.ad.customer_id = customer_id;
    this.preview();
    this.save();
  }

  loadNews() {
    this.apps = this.data.currentUser.customer.apps;

    this.api.getMedias(this.data.currentUser.customer.id, "news").subscribe((data:Media[]) => {
      this.mediaSearchResult = data;
    })

    this.api.getSeries().subscribe((data: Serie[]) => {
      this.series = data;
    })

    this.api.getBylines().subscribe((data:Byline[])=> {
      this.bylines = data;
    });

    let id = +this.route.snapshot.paramMap.get("id");

    if (id != 0) {
      this.heading = "Redigera nyhet"
      this.api.getAd(id).subscribe((data: Ad) => {
        this.ad = data;
        this.preview();
      })
    }
    else {
      this.ad = new Ad;

      this.api.getAdTags(true).subscribe((data: AdTag[]) => {
        this.ad.tags = data;
      })

      this.ad.id = 0;
      this.ad.customer_id = this.data.currentUser.customer.id;
      let now = new Date();
      let stop = new Date(now.getTime() + 10000*24*60*60*1000);
      this.ad.timestart = now.toISOString();
      this.ad.timestop = stop.toISOString();
      this.ad.byline = this.data.currentUser.default_byline;
      if (this.data.currentUser.noPrefilledAdCity != 1)
      {
        this.ad.apps.push(this.data.currentUser.default_app);
      }
      this.ad.preword = this.data.currentUser.default_startword;
      this.ad.vinjett = "Nyheter";
      this.ad.notify = 'yes';
      this.ad.isFree = '0';
      this.ad.notifyAll = '0';
      this.preview_str = "";
      this.preview_bodystr = "";
      this.preview();
    }
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '2rem',
    placeholder: 'Skriv texten här...',
    translate: 'no'
  };

  save(status = -1) {

    if (status == 1 || status == 0 || status == -2)
    {
      this.ad.status = status;
    }
    if (status == 1)
    {
      this.ad.timestart = new Date().toISOString();
    }
    if (!this.ad.body)
    {
      this.ad.body = "";
    }
    this.ad.body = this.ad.body.split('&#10;').join(' ');

    if (!this.ad.customer_id)
    {
      this.ad.customer_id = this.data.currentUser.customer.id;
    }
    let timestart_save = this.ad.timestart;
    let timestart = this.api.getDateStr(new Date(this.ad.timestart));
    this.ad.timestart = timestart;
    let timestop = this.api.getDateStr(new Date(this.ad.timestop));
    this.ad.timestop = timestop;

    this.ad.user_id = this.data.currentUser.id;

    this.api.setAd(this.ad).subscribe((data:Response)=> {
      this.ad.id = data.id;
      this.ad.timestart = timestart_save;
      this.toastr.success("Nyheten sparades", "Sparad", { progressBar: true, closeButton: true });
    }, error => this.api.showError(error));
  }

  setUpdateTime() {

    this.ad.timeupdate = new Date().toISOString();
  
  }

  writeRules(array, error, object)
  {

    if (!error.gramerrors.gramerror.length)
    {

      this.writeRules2(array, error, error.gramerrors.gramerror, object)

    }
    else
    {
      for (let g of error.gramerrors.gramerror)
      {
        this.writeRules2(array, error, g, object)
      }
    }

  }

  writeRules2(array, error, gramerror, object)
  {

    let info = "Ohanterat fel";
    let marked = "";
    let suggestion = "";

    if (gramerror.rule == "inget_finit_verb@verb" || gramerror.rule == "varna_konstig@a" || gramerror.rule == "inget_verb@verb" || gramerror.rule == "inget_subjekt@verb")
    {
      info = gramerror.info;

      let ref = error["@attributes"].ref;

      for (let s of object.s)
      {
        if (s["@attributes"].ref == ref)
        {
          marked = s.text;
        }
      }

    }    
    else if (gramerror.rule == "sma_och_stora@stil")
    {
      info = "Misstänkt stilfel"
      marked = gramerror.marked.emph;
      suggestion = gramerror.suggestions.sugg.emph;
    }
    else if (gramerror.rule == "sär6nn_jjA@sär")
    {
      info = gramerror.info;
      marked = gramerror.marked.emph;
    }
    else if (gramerror.rule == "stav1@stavning")
    {
      info = gramerror.info;
      marked = gramerror.marked.emph;
      if (!gramerror.suggestions || !gramerror.suggestions.sugg || !gramerror.suggestions.sugg.length)
      {
        if (gramerror.suggestions && !gramerror.suggestions.sugg && !gramerror.suggestions.sugg.emph)
        {
          suggestion = gramerror.suggestions.sugg.emph;
        }
        
      }
      else if (gramerror.suggestions && gramerror.suggestions.sugg && gramerror.suggestions.sugg.length)
      {
        let s_a = [];
        for (let sugg of gramerror.suggestions.sugg)
        {
          s_a = s_a.concat(sugg.emph);
        }

        suggestion = s_a.join(", ");

      }
     
    }
    else if (gramerror.info && gramerror.marked && gramerror.marked.emph && gramerror.suggestions && gramerror.suggestions.sugg && gramerror.suggestions.sugg.emph)
    {
      info = gramerror.info;
      marked = gramerror.marked.emph;
      suggestion = gramerror.suggestions.sugg.emph;
    }
    else if (gramerror.info && gramerror.marked && gramerror.marked.emph && gramerror.suggestions && gramerror.suggestions.sugg)
    {
      info = gramerror.info;
      marked = gramerror.marked.emph;
      suggestion = gramerror.suggestions.sugg;
    }
    else
    {
      console.log("ERROR", error);
    }

    if (marked.trim() == "ÖrkelljungaAppen" || marked.trim() == "SävsjöAppen")
    {
      return;
    }

    if (marked.trim().replace(".", "").length < 3)
    {
      console.log("IGNORED", marked);
      return;
    }

    let item = {
      info: info,
      marked: marked,
      suggestion: suggestion,
      color: this.stringToColour(marked + info),
      color2: this.stringToColour(marked + info) + "33",
    }

    array.push(item);

  }

  highlight(errors, id) {


    for (let error of errors)
    {

      if (error.marked)
      {
        let opar = document.getElementById(id).innerHTML;
    
        var re = new RegExp(error.marked , 'g');
    
        document.getElementById(id).innerHTML = opar.replace(re, `<span style='background-color:` + error.color2 + `'>$&</span>`);
      }

    }



  }

  stringToColour = (str: string) => {
    let hash = 0;
    str.split('').forEach(char => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })
    let colour = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      colour += value.toString(16).padStart(2, '0')
    }
    return colour
  }



  preview() {
    if (!this.ad.body)
    {
      this.ad.body = "";
    }
    this.ad.body = this.ad.body.split('&#10;').join(' ');
    this.api.previewAd(this.ad).subscribe((data:any)=> {
      this.preview_str = data.feed;
      this.preview_bodystr = data.full;

      setTimeout( function() {
        var timeElements = document.getElementsByClassName('ad_time');
        for(var i = 0; i < timeElements.length; i++) {
          let time = new Date(timeElements[i].getAttribute('datetime'));
          //@ts-ignore
          timeElements[i].innerText = TimeAgo.inWords(time.getTime());
        }

      //@ts-ignore
      var slider = tns({ container: '.bd_slider',
      items: 1,
      autoHeight: false,
      controls: false,
      nav: false,
      speed: 400,
      autoplay: true,
      mouseDrag: true });
      },1000);

/*
      this.api.spellCheck(this.ad.body).subscribe((data:any)=> {

        this.scrutinizer_body = [];

        for (let s of data.scrutinizer.s)
        {
          if (s.gramerrors)
          {       
            this.writeRules(this.scrutinizer_body, s, data)
          }
        }

        this.highlight(this.scrutinizer_body, "bb");

      })
*/
      if (this.ad.title && this.ad.title.length > 0)
      {
/*
        this.api.spellCheck(this.ad.title).subscribe((data:any)=> {
        
          this.scrutinizer_header = [];
  
          for (let s of data.scrutinizer.s)
          {
            if (s.gramerrors)
            {       
              this.writeRules(this.scrutinizer_header, s, data)
            }
          }
  
          this.highlight(this.scrutinizer_header, "bb");
        })

        */

      }

      if (this.ad.shortbody && this.ad.shortbody.length > 0)
      {
/*
        this.api.spellCheck(this.ad.shortbody).subscribe((data:any)=> {
          this.scrutinizer_puff = [];
  
          for (let s of data.scrutinizer.s)
          {
            if (s.gramerrors)
            {       
              this.writeRules(this.scrutinizer_puff, s, data)
            }
          }
  
          this.highlight(this.scrutinizer_puff, "ba");
  
        })
          */

      }

      


      if (!document.getElementById("template_css_" + data.feed_template_id)) { 
        $("head").append('<style id="template_css_' + data.feed_template_id + '" type="text/css">' + data.feed_css + '</style>'); 
      }
      else {
        $("#template_css_" + data.feed_template_id).text(data.feed_css); 
      }

      if (!document.getElementById("template_css_" + data.full_template_id)) { 
        $("head").append('<style id="template_css_' + data.full_template_id + '" type="text/css">' + data.full_css + '</style>'); 
      }
      else { 
        $("#template_css_" + data.full_template_id).text(data.full_css); 
      }

      let width = this.preview_card.nativeElement.offsetWidth - 40;
      let zoom = width / 1000;
      this.news_preview.nativeElement.style.zoom = zoom ;
      this.news_preview2.nativeElement.style.zoom = zoom ;


    }, error => this.api.showError(error));
  }

  img_search(){

    this.loading_imgs = true;

    if (this.img_subscription && !this.img_subscription.closed) {
      this.img_subscription.unsubscribe();
    }
    this.img_subscription = this.api.searchMedias(this.data.currentUser.customer.id, this.s, "news", this.img_search_method).subscribe((data:Media[]) =>{
      this.mediaSearchResult = data;
      this.loading_imgs = false;
    })
  }

  addimage(m: Media) {
    this.ad.medias.push(m);
    this.preview();
  }

  addimagetext(m: Media) {

      this.ad.body += "<img src='" + this.baseUrl + "/uploads/" + m.src + "' style='width:100%'/>"

   
  }

  removeImage(index) {
    this.ad.medias.splice(index, 1);
    this.preview();
  }

  check_media(m: Media) {
    return !this.ad.medias.find(x => x["id"] === m.id);
  }

  public onUploadInit(args: any): void {
    console.log('onUploadInit:', args);
  }

  public onUploadError(args: any): void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccess(args: any): void {
    let id:number = +args[1];

    this.api.getMedia(id).subscribe((data: Media) => {
      this.ad.medias.push(data);
    //  this.DropZone.directiveRef.reset();
    })
  }

  public delete() {
    if(confirm("Är du säker på att du vill ta bort den här nyheten?")) {
      this.api.delAd(this.ad.id).subscribe((data:any) => {
        this.router.navigateByUrl('/newses');
      });
    }
  }

  public generatePDF() {
    this.api.generatePDF(this.ad.id).subscribe((data:any) => {
      let url = this.baseUrl + "/uploads/ad_" + this.ad.id + ".pdf";
      window.open(url, "_blank");
    })
  }

  public reimport() {
    this.api.reImportAd(this.ad.id).subscribe((data:any) => {
      this.loadNews();
    })
  }

  public openNews(ad_id) {
    this.router.navigateByUrl('/', {skipLocationChange: true})
    .then(()=>this.router.navigateByUrl('/news/' + ad_id));


  }

  public ai_imageSearch()
  {
    let question = "Välj ut fem nyckelord från följande text:\n\n";

    question = question + this.ad.body;

    this.aiButtonImageSearch = false;
    this.ad.medias = []

    this.api.getAiAnswer(question).subscribe(data => {

      data = data.replace("Nyckelord:", " ");

      let keywords = data.split(",");
      
      this.s = "";

      if (keywords.length < 4)
      {
        keywords = data.split("-");
      }

      for(let keyword of keywords)
      {
        this.s = this.s + keyword + " ";
          keyword = keyword.trim();
          this.aiButtonImageSearch = false;
          this.api.searchMedias(this.data.currentUser.customer.id, keyword, "news").subscribe((data: Media[]) => {
              this.mediaSearchResult = data;

              this.aiButtonImageSearch = true;

              if (this.mediaSearchResult.length > 0) {
                this.addimage(this.mediaSearchResult[0]);
              }

            })


      }

      this.aiButtonImageSearch = true;


    });


  }

  ai_socialmediatexts()
  {

    this.aiLoadingSocialMediaText = true;

    let question = "Skriv en kort text till Facebook utifrån följande text:\n\n";

    question = question + this.ad.body;

    this.api.getAiAnswer(question).subscribe(data => {
      this.ad.facebook_text = data;
      this.aiLoadingSocialMediaText = false;
    })

    question = "Skriv en kort text på en mening till Instagram utifrån följande text:\n\n";

    question = question + this.ad.body;

    this.api.getAiAnswer(question).subscribe(data => {
      this.ad.instagram_text = data;
      this.aiLoadingSocialMediaText = false;
    })

  }

  publish_socialmedia(fb = true, ig = true)
  {

    this.ad.apps.forEach(app => {

      console.log(app);

      this.postingFacebook = true; 

      let baseUrl = "https://" + app.portal;

        if (app.facebook_id && fb && app.facebook_token)
        {

          let FacebookPostObject = {
            message: this.ad.facebook_text.replace( /(<([^>]+)>)/ig, ''),
            link: baseUrl + "/news/" + this.ad.id,
            access_token: app.facebook_token
          }

          this.data.fb.api('/' + app.facebook_id + '/feed', 'post', FacebookPostObject).then((res: any) => {

            this.api.showSuccess("Skapad!", "Facebook post skapad");
            this.postingFacebook = false;

          });
        }
        else
        {
          this.postingFacebook = false;
        }

        if (app.instagram_id && ig && app.facebook_token)
        {

          let InstagramPostObject = {
            caption: this.ad.instagram_text.replace( /(<([^>]+)>)/ig, ''),
            image_url: this.ad.medias[0].src,
            access_token: app.facebook_token
          }

          this.data.fb.api('/' + app.instagram_id + '/media', 'post', InstagramPostObject).then((res: any) => {
            this.data.fb.api('/' + app.instagram_id + '/media_publish', 'post', { creation_id: res.id, access_token: app.facebook_token })
                this.api.showSuccess("Skapad!", "Instagram post skapad");
                this.postingInstagram = false;
          });

        }
        else
        {
          this.postingInstagram = false;
        }

    })




  }

  duplicate()
  {

   this.ad.id = 0;
   this.ad.apps = [];
   this.ad.status = 0;

    this.save()


  }
}

