import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgReduxModule} from '@angular-redux/store';
import {NgRedux, DevToolsExtension} from '@angular-redux/store';
import {rootReducer, ArchitectUIState} from './ThemeOptions/store';
import {ConfigActions} from './ThemeOptions/store/config.actions';
import {AppRoutingModule} from './app-routing.module';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';

import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';


import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';

import { DataTablesModule } from 'angular-datatables';

import { FacebookModule } from 'ngx-facebook';


// BOOTSTRAP COMPONENTS
/*



import {LaddaModule} from 'angular2-ladda';
import {NgxLoadingModule} from 'ngx-loading';

import {SweetAlert2Module} from '@toverux/ngx-sweetalert2';


import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {CountUpModule} from 'countup.js-angular2';
import {AgmCoreModule} from '@agm/core';
import {ImageCropperModule} from 'ngx-image-cropper';
import {NgBootstrapFormValidationModule} from 'ng-bootstrap-form-validation';
import {AngularStickyThingsModule} from '@w11k/angular-sticky-things';
import {NouisliderModule} from 'ng2-nouislider';

import {SelectDropDownModule} from 'ngx-select-dropdown';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {JwBootstrapSwitchNg2Module} from 'jw-bootstrap-switch-ng2';
import {TextMaskModule} from 'angular2-text-mask';
import {ClipboardModule} from 'ngx-clipboard';
import {TextareaAutosizeModule} from 'ngx-textarea-autosize';
import {ColorPickerModule} from 'ngx-color-picker';
import {ChartsModule} from 'ng2-charts';

// ANGULAR MATERIAL COMPONENTS


import {MatButtonModule} from '@angular/material';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatChipsModule} from '@angular/material/chips';

import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTreeModule} from '@angular/material/tree';


*/
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';


import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RoundProgressModule} from 'angular-svg-round-progressbar';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {NgSelectModule} from '@ng-select/ng-select';
import {AngularEditorModule} from '@kolkov/angular-editor';

import {MatRadioModule} from '@angular/material/radio';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatExpansionModule} from '@angular/material/expansion';
import {ToastrModule} from 'ngx-toastr';

import { AngularCropperjsModule } from 'angular-cropperjs';

import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxCropperJsModule } from 'ngx-cropperjs-wrapper';

import { DragDropListModule } from 'ng-drag-drop-list';

import {DragDropModule} from '@angular/cdk/drag-drop';

import { MonacoEditorModule } from 'ngx-monaco-editor';


// LAYOUT

import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import {AppsLayoutComponent} from './Layout/apps-layout/apps-layout.component';
import {PagesLayoutComponent} from './Layout/pages-layout/pages-layout.component';
import {ThemeOptions} from './theme-options';
import {OptionsDrawerComponent} from './ThemeOptions/options-drawer/options-drawer.component';
import {PageTitleComponent} from './Layout/Components/page-title/page-title.component';

// HEADER

import {HeaderComponent} from './Layout/Components/header/header.component';
import {DotsComponent} from './Layout/Components/header/elements/dots/dots.component';
import {SearchBoxComponent} from './Layout/Components/header/elements/search-box/search-box.component';
import {MegamenuComponent} from './Layout/Components/header/elements/mega-menu/mega-menu.component';
import {MegapopoverComponent} from './Layout/Components/header/elements/mega-menu/elements/megapopover/megapopover.component';
import {UserBoxComponent} from './Layout/Components/header/elements/user-box/user-box.component';
import {DrawerComponent} from './Layout/Components/header/elements/drawer/drawer.component';

// SIDEBAR

import {SidebarComponent} from './Layout/Components/sidebar/sidebar.component';
import {LogoComponent} from './Layout/Components/sidebar/elements/logo/logo.component';

// FOOTER

import {FooterComponent} from './Layout/Components/footer/footer.component';
import {FooterDotsComponent} from './Layout/Components/footer/elements/footer-dots/footer-dots.component';
import {FooterMenuComponent} from './Layout/Components/footer/elements/footer-menu/footer-menu.component';

// PAGES

import {ForgotPasswordComponent} from './Pages/forgot-password/forgot-password.component';
import {LoginComponent} from './Pages/login/login.component';
import { StartComponent } from './Pages/start/start.component';

import { TrendModule } from 'ngx-trend';
import { AdminUsersComponent } from './Pages/admin-users/admin-users.component';
import { AdminUserEditComponent } from './Pages/admin-user-edit/admin-user-edit.component';
import { AdsComponent } from './Pages/ads/ads.component';
import { AdEditComponent } from './Pages/ad-edit/ad-edit.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { AdminCustomersComponent } from './Pages/admin-customers/admin-customers.component';
import { AdminCustomerEditComponent } from './Pages/admin-customer-edit/admin-customer-edit.component';
import { AdminTemplateEditComponent } from './Pages/admin-template-edit/admin-template-edit.component';
import { AdminTemplatesComponent } from './Pages/admin-templates/admin-templates.component';
import { AdminSectionEditComponent } from './Pages/admin-section-edit/admin-section-edit.component';
import { AdminSectionsComponent } from './Pages/admin-sections/admin-sections.component';
import { AdminAppsComponent } from './Pages/admin-apps/admin-apps.component';
import { AdminAppsEditComponent } from './Pages/admin-apps-edit/admin-apps-edit.component';
import { SetPickerLabels } from './Services/datetimepick-langauge';
import { MediasComponent } from './Pages/medias/medias.component';
import { MediaUploadComponent } from './Pages/media-upload/media-upload.component';
import { MediaEditComponent } from './Pages/media-edit/media-edit.component';
import { MediaCropComponent } from './Pages/media-crop/media-crop.component';
import { ProfileComponent } from './Pages/profile/profile.component';
import { SerieEditComponent } from './Pages/serie-edit/serie-edit.component';
import { SeriesComponent } from './Pages/series/series.component';
import { NewsEditComponent } from './Pages/news-edit/news-edit.component';
import { NewsesComponent } from './Pages/newses/newses.component';
import { BylinesComponent } from './Pages/bylines/bylines.component';
import { BylineEditComponent } from './Pages/byline-edit/byline-edit.component';
import { AdminPlacesComponent } from './Pages/admin-places/admin-places.component';
import { AdminPlaceEditComponent } from './Pages/admin-place-edit/admin-place-edit.component';
import { LunchesComponent } from './Pages/lunches/lunches.component';
import { LunchEditComponent } from './Pages/lunch-edit/lunch-edit.component';
import { AdminEventsComponent } from './Pages/admin-events/admin-events.component';
import { AdminEventEditComponent } from './Pages/admin-event-edit/admin-event-edit.component';
import { FamilyEditComponent } from './Pages/family-edit/family-edit.component';
import { FamilyAdsComponent } from './Pages/family-ads/family-ads.component';
import { AdminRssEditComponent } from './Pages/admin-rss-edit/admin-rss-edit.component';
import { AdminRssesComponent } from './Pages/admin-rsses/admin-rsses.component';
import { AdminSportKeysComponent } from './Pages/admin-sport-keys/admin-sport-keys.component';
import { AdminSportKeyEditComponent } from './Pages/admin-sport-key-edit/admin-sport-key-edit.component';
import { AdminSportTeamsComponent } from './Pages/admin-sport-teams/admin-sport-teams.component';
import { AdminSportTeamEditComponent } from './Pages/admin-sport-team-edit/admin-sport-team-edit.component';
import { ContractsComponent } from './Pages/contracts/contracts.component';
import { ContractEditComponent } from './Pages/contract-edit/contract-edit.component';
import { AdminContractsComponent } from './Pages/admin-contracts/admin-contracts.component';
import { ContactsComponent } from './Pages/contacts/contacts.component';
import { ContactEditComponent } from './Pages/contact-edit/contact-edit.component';
import { SalesContractsComponent } from './Pages/sales-contracts/sales-contracts.component';
import { ChangePasswordComponent } from './Pages/change-password/change-password.component';
import { PagesComponent } from './Pages/pages/pages.component';
import { PageEditComponent } from './Pages/page-edit/page-edit.component';
import { SalesTimelineComponent } from './Pages/sales-timeline/sales-timeline.component';
import { AdminSectionsPagesComponent } from './Pages/admin-sections-pages/admin-sections-pages.component';
import { AdminSectionsPageEditComponent } from './Pages/admin-sections-page-edit/admin-sections-page-edit.component';
import { FollowersComponent } from './Pages/followers/followers.component';
import { FollowerSingleComponent } from './Pages/follower-single/follower-single.component';
import { StatsGeneralComponent } from './Pages/stats-general/stats-general.component';
import { VideoUploadComponent } from './Pages/video-upload/video-upload.component';

import { Ng2GoogleChartsModule } from 'ng2-google-charts';

import { AdminEmailTemplateEditComponent } from './Pages/admin-email-template-edit/admin-email-template-edit.component';
import { AdminEmailTemplatesComponent } from './Pages/admin-email-templates/admin-email-templates.component';
import { SupportVideosComponent } from './Pages/support-videos/support-videos.component';
import { AdminAppUsersComponent } from './Pages/admin-app-users/admin-app-users.component';
import { AdminAppUserEditComponent } from './Pages/admin-app-user-edit/admin-app-user-edit.component';
import { SupportVideos2Component } from './Pages/support-videos2/support-videos2.component';
import { SalesWarningsComponent } from './Pages/sales-warnings/sales-warnings.component';
import { AdminEventCategoryEditComponent } from './Pages/admin-event-category-edit/admin-event-category-edit.component';
import { AdminEventCategoriesComponent } from './Pages/admin-event-categories/admin-event-categories.component';
import { QuizzesComponent } from './Pages/quizzes/quizzes.component';
import { QuizEditComponent } from './Pages/quiz-edit/quiz-edit.component';
import { QuizLiveComponent } from './Pages/quiz-live/quiz-live.component';
import { AdminRecommendsComponent } from './Pages/admin-recommends/admin-recommends.component';
import { AdminRecommendEditComponent } from './Pages/admin-recommend-edit/admin-recommend-edit.component';
import { AdminRecommendsMapComponent } from './Pages/admin-recommends-map/admin-recommends-map.component';
import { AdminRecommendsDuplicatesComponent } from './Pages/admin-recommends-duplicates/admin-recommends-duplicates.component';
import { AdminRecommendsAddComponent } from './Pages/admin-recommends-add/admin-recommends-add.component';
import { AdminDevicesPositionComponent } from './Pages/admin-devices-position/admin-devices-position.component';

import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BattlesComponent } from './Pages/battles/battles.component';
import { BattleQuestionsComponent } from './Pages/battle-questions/battle-questions.component';
import { BattleQuestionEditComponent } from './Pages/battle-question-edit/battle-question-edit.component';
import { ChristmasSignageComponent } from './Pages/christmas-signage/christmas-signage.component';
import { EventsComponent } from './Pages/events/events.component';
import { EventEditComponent } from './Pages/event-edit/event-edit.component';
import { LunchStandardComponent } from './Pages/lunch-standard/lunch-standard.component';
import { DefaultLunchesComponent } from './Pages/default-lunches/default-lunches.component';
import { DefaultLunchEditComponent } from './Pages/default-lunch-edit/default-lunch-edit.component';
import { StatsNewsComponent } from './Pages/stats-news/stats-news.component';
import { ProductsComponent } from './Pages/products/products.component';
import { ProductEditComponent } from './Pages/product-edit/product-edit.component';
import { SupportCustomerEventsComponent } from './Pages/support-customer-events/support-customer-events.component';
import { AdminGiftCardsComponent } from './Pages/admin-gift-cards/admin-gift-cards.component';
import { AdminGiftCardEditComponent } from './Pages/admin-gift-card-edit/admin-gift-card-edit.component';
import { IntranetStartComponent } from './Pages/intranet-start/intranet-start.component';
import { IntranetTasksComponent } from './Pages/intranet-tasks/intranet-tasks.component';
import { IntranetTaskEditComponent } from './Pages/intranet-task-edit/intranet-task-edit.component';
import { IntranetNewsStatsComponent } from './Pages/intranet-news-stats/intranet-news-stats.component';
import { TooltipComponent } from './Layout/Components/page/tooltip/tooltip.component';
import { CroniclesComponent } from './Pages/cronicles/cronicles.component';
import { CronicleEditComponent } from './Pages/cronicle-edit/cronicle-edit.component';
import { LiveEventComponent } from './Pages/live-event/live-event.component';
import { LiveEventsComponent } from './Pages/live-events/live-events.component';
import { AdminMenuComponent } from './Pages/admin-menu/admin-menu.component';
import { AdminFeedbacksComponent } from './Pages/admin-feedbacks/admin-feedbacks.component';
import { NewsEditSummaryComponent } from './Pages/news-edit-summary/news-edit-summary.component';
import { AdminAudioShowsComponent } from './Pages/admin-audio-shows/admin-audio-shows.component';
import { AdminAudioShowEditComponent } from './Pages/admin-audio-show-edit/admin-audio-show-edit.component';
import { AdminVideoShowsComponent } from './Pages/admin-video-shows/admin-video-shows.component';
import { AdminVideoShowEditComponent } from './Pages/admin-video-show-edit/admin-video-show-edit.component';
import { AdminTodaysComponent } from './pages/admin-todays/admin-todays.component';
import { AdminTodayEditComponent } from './pages/admin-today-edit/admin-today-edit.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AdminScreenComponent } from './Components/admin-screen/admin-screen.component';
import { AdminEventUrlsComponent } from './Pages/admin-event-urls/admin-event-urls.component';
import { AdminEventUrlEditComponent } from './Pages/admin-event-url-edit/admin-event-url-edit.component';
import { PortalTextsComponent } from './Pages/portal-texts/portal-texts.component';
import { PortalTextEditComponent } from './Pages/portal-text-edit/portal-text-edit.component';
import { ExternalAdEditComponent } from './Pages/external-ad-edit/external-ad-edit.component';
import { ExternalAdsComponent } from './Pages/external-ads/external-ads.component';

import { AdminProofReadingComponent } from './pages/admin-proof-reading/admin-proof-reading.component';
import { AdminAddFamilypageComponent } from './Pages/admin-add-familypage/admin-add-familypage.component';
import { NewsRewriteComponent } from './Pages/news-rewrite/news-rewrite.component';
import { NewsTagsComponent } from './Pages/news-tags/news-tags.component';
import { ExternalProofReadingComponent } from './Public/external-proof-reading/external-proof-reading.component';
import { HouseingAdsComponent } from './Pages/houseing-ads/houseing-ads.component';
import { HouseingEditComponent } from './Pages/houseing-edit/houseing-edit.component';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  maxFilesize: 50,
  acceptedFiles: 'image/*'
};



@NgModule({
  declarations: [

    // LAYOUT

    AppComponent,
    BaseLayoutComponent,
    AppsLayoutComponent,
    PagesLayoutComponent,
    OptionsDrawerComponent,
    PageTitleComponent,

    // HEADER

    HeaderComponent,
    DotsComponent,
    SearchBoxComponent,
    MegamenuComponent,
    MegapopoverComponent,
    UserBoxComponent,
    DrawerComponent,

    // SIDEBAR

    SidebarComponent,
    LogoComponent,

    // FOOTER

    FooterComponent,
    FooterDotsComponent,
    FooterMenuComponent,

    // PAGES

    ForgotPasswordComponent,
    LoginComponent,
    StartComponent,
    AdminUsersComponent,
    AdminUserEditComponent,
    AdsComponent,
    AdEditComponent,
    AdminCustomersComponent,
    AdminCustomerEditComponent,
    AdminTemplateEditComponent,
    AdminTemplatesComponent,
    AdminSectionEditComponent,
    AdminSectionsComponent,
    AdminAppsComponent,
    AdminAppsEditComponent,
    MediasComponent,
    MediaUploadComponent,
    MediaEditComponent,
    MediaCropComponent,
    ProfileComponent,
    SerieEditComponent,
    SeriesComponent,
    NewsEditComponent,
    NewsesComponent,
    BylinesComponent,
    BylineEditComponent,
    AdminPlacesComponent,
    AdminPlaceEditComponent,
    LunchesComponent,
    LunchEditComponent,
    AdminEventsComponent,
    AdminEventEditComponent,
    FamilyEditComponent,
    FamilyAdsComponent,
    AdminRssEditComponent,
    AdminRssesComponent,
    AdminSportKeysComponent,
    AdminSportKeyEditComponent,
    AdminSportTeamsComponent,
    AdminSportTeamEditComponent,
    ContractsComponent,
    ContractEditComponent,
    AdminContractsComponent,
    ContactsComponent,
    ContactEditComponent,
    SalesContractsComponent,
    ChangePasswordComponent,
    PagesComponent,
    PageEditComponent,
    SalesTimelineComponent,
    AdminSectionsPagesComponent,
    AdminSectionsPageEditComponent,
    FollowersComponent,
    FollowerSingleComponent,
    StatsGeneralComponent,
    VideoUploadComponent,
    AdminEmailTemplateEditComponent,
    AdminEmailTemplatesComponent,
    SupportVideosComponent,
    AdminAppUsersComponent,
    AdminAppUserEditComponent,
    SupportVideos2Component,
    SalesWarningsComponent,
    AdminEventCategoryEditComponent,
    AdminEventCategoriesComponent,
    QuizzesComponent,
    QuizEditComponent,
    QuizLiveComponent,
    AdminRecommendsComponent,
    AdminRecommendEditComponent,
    AdminRecommendsMapComponent,
    AdminRecommendsDuplicatesComponent,
    AdminRecommendsAddComponent,
    AdminDevicesPositionComponent,
    BattlesComponent,
    BattleQuestionsComponent,
    BattleQuestionEditComponent,
    ChristmasSignageComponent,
    EventsComponent,
    EventEditComponent,
    LunchStandardComponent,
    DefaultLunchesComponent,
    DefaultLunchEditComponent,
    StatsNewsComponent,
    ProductsComponent,
    ProductEditComponent,
    SupportCustomerEventsComponent,
    AdminGiftCardsComponent,
    AdminGiftCardEditComponent,
    IntranetStartComponent,
    IntranetTasksComponent,
    IntranetTaskEditComponent,
    IntranetNewsStatsComponent,
    TooltipComponent,
    CroniclesComponent,
    CronicleEditComponent,
    LiveEventComponent,
    LiveEventsComponent,
    AdminMenuComponent,
    AdminFeedbacksComponent,
    NewsEditSummaryComponent,
    AdminAudioShowsComponent,
    AdminAudioShowEditComponent,
    AdminVideoShowsComponent,
    AdminVideoShowEditComponent,
    AdminTodaysComponent,
    AdminTodayEditComponent,
    AdminScreenComponent,
    AdminEventUrlsComponent,
    AdminEventUrlEditComponent,
    PortalTextsComponent,
    PortalTextEditComponent,
    ExternalAdEditComponent,
    ExternalAdsComponent,
    AdminProofReadingComponent,
    AdminAddFamilypageComponent,
    NewsRewriteComponent,
    NewsTagsComponent,
    ExternalProofReadingComponent,
    HouseingAdsComponent,
    HouseingEditComponent

  ],
  imports: [
    DropzoneModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,
    DataTablesModule,
    AngularEditorModule,
    MatRadioModule,
    MatSelectModule,
    MatFormFieldModule,
    MatExpansionModule,
    ToastrModule.forRoot(),
    MonacoEditorModule.forRoot(),
    FacebookModule.forRoot(),
    EditorModule,
    ImageCropperModule,
    NgxCropperJsModule,

    AngularCropperjsModule,

    OwlDateTimeModule,
    OwlNativeDateTimeModule,

    DragDropListModule,
    DragDropModule,
    Ng2GoogleChartsModule,

    MatIconModule,

    // Angular Bootstrap Components
    MatButtonModule,
    MatCheckboxModule,
    MatTooltipModule,
    NgbModule,
    RoundProgressModule,
    SlickCarouselModule,

    PerfectScrollbarModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    TrendModule,
    HttpClientModule,
    DropzoneModule,
    FontAwesomeModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    })
  ],
  providers: [
    {
      provide:
      PERFECT_SCROLLBAR_CONFIG,
      useValue:
      DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'sv'},
    {provide: OwlDateTimeIntl, useClass: SetPickerLabels},
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    ConfigActions,
    ThemeOptions
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
              private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}

