import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AdTag } from '../../../../../shared/models/adtag';
import { DataService } from 'src/app/Services/data.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-news-tags',
  templateUrl: './news-tags.component.html',
  styleUrls: ['./news-tags.component.scss']
})
export class NewsTagsComponent implements OnInit {

  baseUrl:string = environment.apiUrl;
  heading = 'Nyhets-taggar';
  subheading = 'Här ändrar du nyhetsaggar';
  icon = 'pe-7s-server icon-gradient bg-malibu-beach';

  public adTags: AdTag[] = [];
  public tag = new AdTag();

  constructor(private data: DataService, private api: ApiService, private route: ActivatedRoute,
    private router: Router, private toastr: ToastrService) {
  

  }

  ngOnInit() {

    this.api.getAdTags().subscribe((data: AdTag[]) => {
      this.adTags = data;
    });


  }

  show(id)
  {

    let tag = this.adTags.find(x => x.id == id);
    tag.show = 1;
    this.api.updateAdTag(tag).subscribe((data: AdTag[]) => {
        this.adTags = data;
    });

  }

  hide(id)
  {

    let tag = this.adTags.find(x => x.id == id);
    tag.show = 0;
    this.api.updateAdTag(tag).subscribe((data: AdTag[]) => {
        this.adTags = data;
    });

  }

  up(id)
  {
    let tag = this.adTags.find(x => x.id == id);
    tag.sort = tag.sort - 3;
    this.api.updateAdTag(tag).subscribe((data: AdTag[]) => {
        this.adTags = data;
    });
  }

  down(id)
  {
    let tag = this.adTags.find(x => x.id == id);
    tag.sort = tag.sort + 3;
    this.api.updateAdTag(tag).subscribe((data: AdTag[]) => {
        this.adTags = data;
    });
  }

  edit(id)
  {
    this.tag = this.adTags.find(x => x.id == id);
  }

  save()
  {

    this.api.updateAdTag(this.tag).subscribe((data: AdTag[]) => {

      this.toastr.success("Taggen sparades", "Sparad", { progressBar: true, closeButton: true });

      this.adTags = data;
      this.tag = new AdTag();
  });

  }

  new()
  {
    this.tag = new AdTag();
  }

 

}
